import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Listbox } from "@headlessui/react";
import { Transition } from "@headlessui/react";
import { ThemeProvider, createTheme } from "@mui/material";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import { toast } from "react-toastify";
// import { fleetData } from "../Data/FleetData";
import Pagination from "@mui/material/Pagination";
import Loader from "./Loader";
// import TotalOrdersChart from "../Charts/TotalOrdersChart";
import Calendar from "../components/Calendar";
// import DoughnutChart from "../Charts/DoughnutChart";
import Header from "../components/Header";
import DashboardBottomBar from "../components/DashboardBottomBar";
import axios from "axios";
import { fetchUserPermissions } from "../redux/slice/permissionSlice";

import * as XLSX from "xlsx";
import { TripLog } from "./TripLog";

const MissionReports = (props) => {
  const [chartOrderData, setChartOrderData] = useState();
  const [analyticsData, setAnalyticsData] = useState({});
  const [isFleetDropdownOpen, setIsFleetDropdownOpen] = useState(false);
  const [selectedFleetDetails, setSelectedFleetDetails] = useState([]);
  const [isRobotDropdownOpen, setIsRobotDropdownOpen] = useState(false);
  const [selectedRobotDetails, setSelectedRobotDetails] = useState([]);
  const [currentSize, setCurrentSize] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showCount, setShowCount] = useState();
  const [paginationlength, setPaginationLength] = useState(false);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const size = 5;
  const token = localStorage.getItem("token");
  const fleetData = JSON.parse(localStorage.getItem("fleetData"));
  // const navigate = useNavigate();
  const [distanceUnit, setDistanceUnit] = useState(
    fleetData?.booking?.analyticsConfig?.defaultDistanceUnit
  );
  const isopen = useSelector((state) => state.dashboardopenReducer);

  const { fleetList } = useSelector((state) => state.fleetList);
  const { startDate, endDate } = useSelector(
    (state) => state.datefilterreducer
  );
  const { userPermission } = useSelector((state) => state.permissionReducer);

  const paginationTheme = createTheme({
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            "& .MuiPaginationItem-root": {
              color: "var(--textColor)",
              height: "28px",
              minWidth: "28px",
            },
          },
          ul: {
            "& .Mui-selected": {
              backgroundColor: "#1C1E2A !important", // background color for the selected option
              color: "white !important", // text color for the selected option
              "&:hover": {
                backgroundColor: "#0099C0", // background color for the selected option on hover
              },
            },
          },
          // li: {
          //   root: {
          //     "& .MuiPaginationItem-text": {
          //       color: "white",
          //     },
          //   },
          // },
        },
      },
    },
  });
  const emailId = localStorage.getItem("useremail");
  useEffect(() => {
    dispatch(fleetListAndRobotStatusByUserEmail(emailId));
  }, []);

  const handlePagination = (event, value) => {
    setPage(value);
    setCurrentPage(value);
  };
  function isColumnEmpty(column) {
    return column.every(
      (value) => value === 0 || value === "-" || value === undefined
    );
  }
  function calculateDistance(distance) {
    switch (distanceUnit) {
      case "miles":
        return (distance * 0.00062137).toFixed(2);
      case "KM":
        return (distance * 0.001).toFixed(2);
        case "meters":
          return distance;
      default:
        return (distance * 0.001).toFixed(2);
    }
  }
  function filterEmptyColumns(data) {
    const columns = Object.keys(data[0]);
    const nonEmptyColumns = columns.filter(
      (column) => !isColumnEmpty(data.map((row) => row[column]))
    );
    return data.map((row) => {
      const filteredRow = {};
      nonEmptyColumns.forEach((column) => {
        filteredRow[column] = row[column];
      });
      return filteredRow;
    });
  }

  useEffect(() => {
    if (!userPermission?.length) {
      dispatch(fetchUserPermissions());
    }
  }, [dispatch, userPermission]);
  useEffect(() => {
    if (loader) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [loader]);

  const isSelectedFleet = (incomingFleet) => {
    return selectedFleetDetails.find(
      (fleet) => fleet.fleetName === incomingFleet.fleetName
    )
      ? true
      : false;
  };

  const isSelectedRobot = (incomingRobot) => {
    return selectedRobotDetails.find(
      (robot) => robot.robotId === incomingRobot.robotId
    )
      ? true
      : false;
  };

  const handleFleetSelect = (incomingFleet) => {
    if (!isSelectedFleet(incomingFleet)) {
      if (selectedRobotDetails.length > 0) {
        setSelectedRobotDetails([]);
      }
      const selectedFleetUpdated = [
        ...selectedFleetDetails,
        fleetList.find((fleet) => fleet.fleetName === incomingFleet.fleetName),
      ];
      setSelectedFleetDetails(selectedFleetUpdated);
    } else {
      handleFleetDeselect(incomingFleet);
    }
    // setIsFleetDropdownOpen(true);
  };

  const handleRobotSelect = (incomingRobot) => {
    if (!isSelectedRobot(incomingRobot)) {
      const selectedRobotUpdated = [
        ...selectedRobotDetails,
        []
          .concat(...fleetList.map((fleet) => fleet.robots))
          .find((robot) => robot.robotId === incomingRobot.robotId),
      ];
      setSelectedRobotDetails(selectedRobotUpdated);
    } else {
      handleRobotDeselect(incomingRobot);
    }
    // setIsFleetDropdownOpen(true);
  };

  const handleFleetDeselect = (incomingFleet) => {
    if (selectedRobotDetails.length > 0) {
      setSelectedRobotDetails([]);
    }
    const selectedFleetDetailsUpdated = selectedFleetDetails.filter(
      (fleet) => fleet.fleetName !== incomingFleet.fleetName
    );
    setSelectedFleetDetails(selectedFleetDetailsUpdated);
    setIsFleetDropdownOpen(true);
  };

  const handleRobotDeselect = (incomingRobot) => {
    const selectedRobotDetailsUpdated = selectedRobotDetails.filter(
      (robot) => robot.robotId !== incomingRobot.robotId
    );
    setSelectedRobotDetails(selectedRobotDetailsUpdated);
    setIsRobotDropdownOpen(true);
  };

  const locationPositionLabelPriority = (position) => {
    if (position && position.locationName) {
      return ``;
    }

    if (position && position.latitude) {
      return `(Lat,Lng)`;
    }

    if (position && position.positionX) {
      return "X,Y";
    }
    return "";
  };

  const locationPositionValuePriority = (position) => {
    if (position && position.locationName) {
      return position.locationName;
    }

    if (position && position.latitude) {
      return `${position.latitude}, ${position.longitude}`;
    }

    if (position && position.positionX) {
      return `${position.positionX}, ${position.positionY}`;
    }
    return "-";
  };

  const fleetBasedRobotList = () => {
    if (selectedFleetDetails.length > 0) {
      return [].concat(
        ...selectedFleetDetails.map((fleet) => fleet.robotsDetails)
      );
      // selectedFleetDetails.find(
      //   (fleet) => fleet.fleetName === incomingFleet.fleetName
      // )
    } else {
      return [].concat(...fleetList.map((fleet) => fleet.robotsDetails));
    }
  };

  const handleExportData = async () => {
    setLoader(true);
    const allLogsRecord = await fetchExcelRecords();

    const excelAllRows = [];
    allLogsRecord.map((log) => {
      let newTime = log.time.split("::")[0].split("-");
      let tempDate = new Date(`${newTime[2]}-${newTime[1]}-${newTime[0]}`);
      let month =  tempDate.toLocaleString('default', { month: 'long' }).substring(0,3); // Months start at 0!

      newTime = newTime[0]+"-"+month+"-"+newTime[2];
   
      let excelRow = {
        date: newTime,
        time: log.time.split("::")[1],
        robotId: log.robotId,
        action:
          log.action === "pin entered" && log.pin
            ? `Pin ${log.pin} entered`
            : log.action,
      };
      excelAllRows.push(excelRow);
      return null;
    });
    let exportedExcelSheetName = `ottonomylogs${
      startDate
        ? new Date(startDate).toLocaleString("en-US").split(",")[0]
        : new Date().toLocaleString("en-US").split(",")[0]
    } - ${
      endDate
        ? new Date(endDate).toLocaleString("en-US").split(",")[0]
        : new Date().toLocaleString("en-US").split(",")[0]
    }`;
    const filteredData = filterEmptyColumns(excelAllRows);

    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    const columnWidths = excelAllRows.reduce((acc, row) => {
      Object.keys(row).map((key, colIndex) => {
        const cellContent = row[key];
        const cellLength = cellContent ? cellContent.toString().length : 0;
        acc[colIndex] = Math.max(acc[colIndex] || 0, cellLength);
        return null;
      });
      return acc;
    }, []);

    // Set column widths in the !cols property of the worksheet
    worksheet["!cols"] = columnWidths.map((width) => ({ width: width + 2 }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "sheet1");
    XLSX.writeFile(workbook, exportedExcelSheetName + ".xlsx");
    setLoader(false);
  };

  const handleExportDataToCsv = async () => {
      setLoader(true);
      const allLogsRecord = await fetchExcelRecords();
      const excelAllRows = [];
      allLogsRecord.map((log) => {
        let newTime = log.time.split("::")[0].split("-");
        let tempDate = new Date(`${newTime[2]}-${newTime[1]}-${newTime[0]}`);
        let month =  tempDate.toLocaleString('default', { month: 'long' }).substring(0,3); // Months start at 0!
  
        newTime = newTime[0]+"-"+month+"-"+newTime[2];
     
        let excelRow = {
          date: newTime,
          time: log.time.split("::")[1],
          robotId: log.robotId,
          action:
            log.action === "pin entered" && log.pin
              ? `Pin ${log.pin} entered`
              : log.action,
        };
        excelAllRows.push(excelRow);
        return null;
      });
      let exportedExcelSheetName = `ottonomylogs${
        startDate
          ? new Date(startDate).toLocaleString("en-US").split(",")[0]
          : new Date().toLocaleString("en-US").split(",")[0]
      } - ${
        endDate
          ? new Date(endDate).toLocaleString("en-US").split(",")[0]
          : new Date().toLocaleString("en-US").split(",")[0]
      }`;
      const filteredData = filterEmptyColumns(excelAllRows);
  
      const worksheet = XLSX.utils.json_to_sheet(filteredData);
  
      const columnWidths = excelAllRows.reduce((acc, row) => {
        Object.keys(row).map((key, colIndex) => {
          const cellContent = row[key];
          const cellLength = cellContent ? cellContent.toString().length : 0;
          acc[colIndex] = Math.max(acc[colIndex] || 0, cellLength);
          return null;
        });
        return acc;
      }, []);
  
      // Set column widths in the !cols property of the worksheet
      worksheet["!cols"] = columnWidths.map((width) => ({ width: width + 2 }));
  
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "sheet1");
      XLSX.writeFile(workbook, exportedExcelSheetName + ".csv");
      setLoader(false);
  }

  const updateState = useCallback(async () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}mission/logs`,
        {
          // fleetId: selectedFleetDetails,
          fleetId: [].concat(...selectedFleetDetails.map((fleet) => fleet._id)),
          robotId: [].concat(
            ...selectedRobotDetails.map((robot) => robot.robotId)
          ),
          // fleetId: [props?.fleetData?._id],
          startDate: new Date(startDate ?? new Date())?.toLocaleDateString(
            "en-US"
          ),
          endDate: new Date(endDate ?? new Date())?.toLocaleDateString("en-US"),
          pageNo: page,
          size: size,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log();
        setChartOrderData(res?.data?.result?.logs);
        setTotalCount(res?.data?.result?.count);
        setCurrentSize(res?.data?.result?.pageOrdersCount);
        if (res.data.result.count > size) {
          setPaginationLength(true);
        }
        if (res.data.result.count % size !== 0) {
          setShowCount(Math.floor(res.data.result.count / size) + 1);
        } else {
          setShowCount(res.data.result.count / size);
          {
            /* setShowCount(Math.floor(res?.data?.data?.order?.length / 5); */
          }
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
        // toast.error("Something went wrong!");
      });
  }, [
    size,
    startDate,
    endDate,
    currentPage,
    selectedFleetDetails,
    selectedRobotDetails,
  ]);

  const fetchExcelRecords = async () => {
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}mission/logs`,
        {
          // fleetId: selectedFleetDetails,
          fleetId: [].concat(...selectedFleetDetails.map((fleet) => fleet._id)),
          robotId: [].concat(
            ...selectedRobotDetails.map((robot) => robot.robotId)
          ),
          // fleetId: [props?.fleetData?._id],
          startDate: new Date(startDate ?? new Date())?.toLocaleDateString(
            "en-US"
          ),
          endDate: new Date(endDate ?? new Date())?.toLocaleDateString("en-US"),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res);
      return res?.data?.result?.logs;
    } catch (err) {
      console.error(err);
      setLoader(false);
      toast.error(err.message);
    }
  };

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();
    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);

  return (
    <>
      <Header />
      {loader && (
        <div className="fixed inset-0 flex items-center justify-center w-screen h-screen z-20 bg-[rgba(0,0,0,0.8)]">
          <div className="p-3 rounded-lg text-center">
            <Loader />
            <p className="text-textColor">Downloading...</p>
          </div>
        </div>
      )}

      <div className={isopen.userlogindata ? "" : "flex flex-col gap-4 h-full"}>
        <div
          className={`${
            isFleetDropdownOpen || isRobotDropdownOpen ? "block" : "hidden"
          } fixed z-[2] w-screen h-screen`}
          onClick={() => {
            isFleetDropdownOpen && setIsFleetDropdownOpen(false);
            isRobotDropdownOpen && setIsRobotDropdownOpen(false);
          }}
        ></div>
        <div className="grid grid-cols-3 gap-3">
          {/* <div className="grid col-span-3 min-h-[50px] text-textColor bg-primaryColor py-2 px-[30px] rounded-lg ">
          <span className="flex items-center text-lg font-semibold font-poppins">
            Data Analytics
          </span>
        </div> */}
          <div className="grid grid-cols-3 col-span-3 gap-3">
            <div className="flex flex-col gap-1">
              <span className="text-sm font-semibold text-gray-400">
                Select Date
              </span>

              <Calendar setPage={setPage}/>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-sm font-semibold text-gray-400">
                Select Fleet
              </span>

              <Listbox
                as="div"
                className="space-y-1"
                value={selectedFleetDetails}
                onChange={(value) => handleFleetSelect(value)}
                open={isFleetDropdownOpen}
              >
                <div className="relative">
                  <span className="inline-block w-full rounded-md shadow-sm">
                    <Listbox.Button
                      className="text-textColor w-full text-left text-sm bg-primaryColor py-2.5 px-3 relative cursor-pointer transition duration-150 ease-in-out rounded-md focus:outline-none sm:text-sm sm:leading-5"
                      onClick={() =>
                        setIsFleetDropdownOpen(!isFleetDropdownOpen)
                      }
                      open={isFleetDropdownOpen}
                    >
                      <span className="block truncate">
                        {selectedFleetDetails.length < 1
                          ? "All"
                          : `Selected ${
                              selectedFleetDetails.length === 1
                                ? "Fleet"
                                : "Fleets"
                            } (${selectedFleetDetails.length})`}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </Listbox.Button>
                  </span>

                  <Transition
                    unmount={false}
                    show={isFleetDropdownOpen}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute z-10 w-full mt-1 rounded-md"
                  >
                    <Listbox.Options
                      static
                      className={`mt-0 p-0 z-10 overflow-auto text-base leading-6 rounded-lg bg-primaryColor border-2 !border-ottonomyBlue shadow-xs focus:outline-none sm:text-sm sm:leading-5 w-full ${
                        fleetList.length > 4 && "overflow-y-scroll h-40"
                      } list-none`}
                    >
                      {fleetList.map((fleet, index) => {
                        const selected = isSelectedFleet(fleet);
                        return (
                          <Listbox.Option key={fleet} value={fleet}>
                            {({ active }) => (
                              <div
                                className={`${
                                  active
                                    ? "text-textColor bg-ottonomyBlue"
                                    : "text-textColor"
                                } cursor-pointer select-none relative py-2 pl-10 pr-4`}
                              >
                                <span
                                  className={`${
                                    selected ? "font-semibold" : "font-normal"
                                  } block truncate text-sm`}
                                >
                                  {fleet.fleetName}
                                </span>
                                {selected && (
                                  <span
                                    className={`${
                                      active
                                        ? "text-textColor"
                                        : "text-ottonomyBlue"
                                    } absolute inset-y-0 left-2 flex items-center`}
                                  >
                                    <svg
                                      className="w-5 h-5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            )}
                          </Listbox.Option>
                        );
                      })}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>

              {/* <Menu
              as="div"
              className="text-textColor text-sm bg-primaryColor py-2.5 px-3 rounded-md relative"
            >
              <div>
                <Menu.Button className="flex justify-between w-full h-auto focus:outline-none ">
                  {Object.keys(selectedFleetDetails).length === 0
                    ? "All"
                    : selectedFleetDetails.fleetName}

                  <SlArrowDown className="ml-2 mt-1 font-semibold text-[#B7B7B7]" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`absolute right-0 z-10 w-full ${
                    fleetList.length > 4 && "overflow-y-scroll h-40"
                  } mt-3 origin-top-right bg-primaryColor border-2 !border-ottonomyBlue divide-y divide-gray-100 rounded-lg focus:outline-none`}
                >
                  <div className="w-full">
                  <Menu.Item key={"robot__no_all"} value={"All"}>
                      {({ active }) => (
                        <button
                          onClick={() => setSelectedFleetDetails([])}
                          className={`${
                            active && "!bg-ottonomyBlue"
                          } group flex w-full px-2 items-center justify-start rounded-md py-2 text-sm text-textColor`}
                        >
                          <span className="text-left line-clamp-1">All</span>
                        </button>
                      )}
                    </Menu.Item>
                    {fleetList &&
                      fleetList?.map((fleet, index) => {
                        return (
                          <Menu.Item
                            key={"fleet__no" + index}
                            value={fleet._id}
                          >
                            {({ active }) => (
                              <button
                                onClick={() => setSelectedFleetDetails(fleet)}
                                className={`${
                                  active && "bg-ottonomyBlue"
                                } group flex w-full px-2 items-center justify-start rounded-md py-2 text-sm text-textColor`}
                              >
                                <span className="text-left line-clamp-1">
                                  {fleet.fleetName}
                                </span>
                              </button>
                            )}
                          </Menu.Item>
                        );
                      })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu> */}
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-sm font-semibold text-gray-400">
                Select Robot
              </span>

              <Listbox
                as="div"
                className="space-y-1"
                value={selectedRobotDetails}
                onChange={(value) => handleRobotSelect(value)}
                open={isRobotDropdownOpen}
              >
                <div className="relative">
                  <span className="inline-block w-full rounded-md shadow-sm">
                    <Listbox.Button
                      className="text-textColor w-full text-left text-sm bg-primaryColor py-2.5 px-3 relative cursor-pointer transition duration-150 ease-in-out rounded-md focus:outline-none sm:text-sm sm:leading-5"
                      onClick={() =>
                        setIsRobotDropdownOpen(!isRobotDropdownOpen)
                      }
                      open={isRobotDropdownOpen}
                    >
                      <span className="block truncate">
                        {selectedRobotDetails.length < 1
                          ? "All"
                          : `Selected ${
                              selectedRobotDetails.length === 1
                                ? "Robot"
                                : "Robots"
                            } (${selectedRobotDetails.length})`}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </Listbox.Button>
                  </span>

                  <Transition
                    unmount={false}
                    show={isRobotDropdownOpen}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute z-10 w-full mt-1 rounded-md"
                  >
                    <Listbox.Options
                      static
                      className={`mt-0 p-0 z-10 overflow-auto text-base leading-6 rounded-lg bg-primaryColor border-2 !border-ottonomyBlue shadow-xs focus:outline-none sm:text-sm sm:leading-5 w-full ${
                        fleetBasedRobotList().length > 4 &&
                        "overflow-y-scroll h-40"
                      } list-none`}
                    >
                      {fleetBasedRobotList().map((robot, index) => {
                        const selected = isSelectedRobot(robot);
                        return (
                          <Listbox.Option key={robot} value={robot}>
                            {({ active }) => (
                              <div
                                className={`${
                                  active
                                    ? "text-textColor bg-ottonomyBlue"
                                    : "text-textColor"
                                } cursor-pointer select-none relative py-2 pl-10 pr-4`}
                              >
                                <span
                                  className={`${
                                    selected ? "font-semibold" : "font-normal"
                                  } block truncate text-sm`}
                                >
                                  {robot.displayRobotName
                                    ? robot.displayRobotName
                                    : robot.robotId}
                                </span>
                                {selected && (
                                  <span
                                    className={`${
                                      active
                                        ? "text-textColor"
                                        : "text-ottonomyBlue"
                                    } absolute inset-y-0 left-2 flex items-center`}
                                  >
                                    <svg
                                      className="w-5 h-5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            )}
                          </Listbox.Option>
                        );
                      })}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </div>
        </div>


        <div className="w-full  min-h-[255px] max-h-[255px]">
              <div className="flex flex-row w-full h-full">
                      <TripLog startDate={startDate} endDate={endDate}/>
              </div>
        </div>

        <div className="flex flex-col items-start h-full min-h-1/2 w-full">
          <div className="flex items-center justify-between w-full">
            <span className="text-lg font-semibold text-ottonomyBlue ">
              Mission Logs
            </span>
            {chartOrderData?.length > 0 && (
              <div className="flex items-center justify-center px-3 py-1 rounded-md bg-ottonomyBlue hover:cursor-pointer">
                {/* <button onClick={handleExportData} className="text-textColor">
                  {" "}
                  <img
                    className="Export_excel_image "
                    src="/assets/images/Export_excel.svg"
                    alt=""
                  />
                  Export Excel
                </button> */}
                <button onClick={handleExportDataToCsv} className="text-white">
                  {" "}
                  <img
                    className="Export_excel_image "
                    src="/assets/images/Export_excel.svg"
                    alt=""
                  />
                  Export To CSV
                </button>
              </div>
            )}
          </div>
          <div className="mt-2 w-full h-full bg-primaryColor flex flex-col gap-2 p-4 pt-2 overflow-auto rounded-md shadow-md">
            <table className="h-full text-left gap-3">
              <thead className="text-[rgb(255,255,255,0.7)] text-left text-sm border-b !border-b-ottonomyBlue">
                <tr>
                  <th className="p-3">Time Stamp</th>
                  <th className="p-3">Action</th>
                  <th className="p-3">Robot Name</th>
                </tr>
              </thead>
              <tbody className="text-textColor">
                {(!chartOrderData || chartOrderData.length === 0) && (
                  <tr className={`rounded-t-md`}>
                    <td
                      align="center"
                      colSpan={8}
                      className="p-2 font-semibold text-gray-400 rounded-b-md !text-base"
                    >
                      No info to show
                    </td>
                  </tr>
                )}
                {chartOrderData !== undefined &&
                  chartOrderData.length > 0 &&
                  chartOrderData.map((log, index) => {
                    let temp = log.time.split("::");
                    let newTime = temp[0].split("-");
                    let tempDate = new Date(`${newTime[2]}-${newTime[1]}-${newTime[0]}`);
                    let month =  tempDate.toLocaleString('default', { month: 'long' }).substring(0,3); // Months start at 0!

                    newTime = newTime[0]+"-"+month+"-"+newTime[2]+"::"+temp[1];
                    
                    return (

                      <tr className="h-[30px]">
                      {/* <td className={` p-2`}>{log.time}</td> */}
                      <td className={` p-2`}>{newTime}</td>
                      <td className={` p-2`}>
                        {log.action === "pin entered" && log.pin
                          ? `Pin ${log.pin} entered`
                          : log.action}
                      </td>

                      <td className={`p-2`}>{log.robotId}</td>
                    </tr>
                    );
                  })}
              </tbody>
            </table>
            {chartOrderData && chartOrderData.length > 0 && (
              <div className="flex justify-between px-2.5 mt-2">
                <span className="font-bold text-ottonomyBlue">
                  {" "}
                  {currentSize < size
                    ? size - size
                    : (page - 1) * size + 1} -{" "}
                  {page * size >= totalCount ? totalCount : page * size} of{" "}
                  {totalCount}{" "}
                </span>

                <div>
                  {paginationlength && (
                    <ThemeProvider theme={paginationTheme}>
                      <Pagination
                        className="PaginationWrapper"
                        count={showCount}
                        page={page}
                        onChange={handlePagination}
                        sx={{ width: "100%" }}
                      />
                    </ThemeProvider>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionReports;
