import React, { useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import "../App.css";
// import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
// import Battery80Icon from "@mui/icons-material/Battery80";
// import PauseCircleIcon from "@mui/icons-material/PauseCircle";
// import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  changeheadlightStatus,
  changeringlightStatus,
  changeRobotWanderStatus,
  closedoorone,
  closedoortwo,
  EmergencyStop,
  getfleetzones,
  getgeofenceZone,
  getnogozones,
  getrobotpathMain,
  getrobotStatus,
  opendoorone,
  opendoortwo,
  pauserobot,
  playrobot,
  ReleaseEmergencyStop,
  resetDiagnostics,
  restartvideo,
  robotactionapi,
  sendhome,
} from "../API";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
// import GoogleMapMain from "../subcomponents/GoogleMapMain";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import OpenSSHModal from "../RobotActions/OpenSSHModal";
import RobotActions from "../RobotActions/RobotActions";
import TeleopCompleted from "../RobotActions/TeleopCompleted";
import AutorunMoving from "../RobotActions/AutorunMoving";
// import DisengageMotorsModal from "../RobotActions/DisengageMotorsModal";
import LogOutModal from "./LogOutModal";
import MapboxMain from "./MapboxMain";
import { getRobotDetail, storeRobotDetails } from "../redux/Actions";
import { useCallback } from "react";
import { useRef } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { useHasPermissionInAnyFleet } from "../customHooks/PermissionHooks";
import { useSearchParams } from "react-router-dom";

function RobotIndividual(dashboardopen) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const auth = queryParams.get("auth");
  const [hideLogout, setHideLogout] = useState(false);
  const [hideNavigation,setHideNavigation] = useState(false);
  let token = localStorage.getItem("token");
  useEffect(() => {
    if(auth){
      setHideLogout(true)
      setHideNavigation(true)
      token = auth
    }
  })
  const hasPermissionForRobotStream =
    useHasPermissionInAnyFleet("robot-stream");
  const hasPermissionForRestartStream =
    useHasPermissionInAnyFleet("restart-stream");
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  const [robotData, setrobotData] = useState();
  const navigate = useNavigate();
  const isLogged = localStorage.getItem("isLoggedin");
  const state2 = useLocation();
  // console.log('robotDetailsrobotDetailsrobotDetailsrobotDetails',robotDetails)
  const [playbtn, setplaybtn] = useState(false);
  // const [googleMap, setgoogleMap] = useState();
  const [robotpathmain, setrobotpathmain] = useState();
  let fleetIdFromState = state2?.state?.data?.fleetId;
  let robotIdFromState = state2?.state?.data?.robotId;
  const [isstop, setisStop] = useState();
  const [fleetId,setFleetId] = useState();
  const [robotId,setRobotId] = useState(window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  ));
  const [geofencezone, setgeofencezone] = useState();
  const [fleetzones, setfleetzones] = useState();
  const [nogozones, setnogozones] = useState();
  const dispatch = useDispatch();
  const toastmsg = (msg) => toast(msg);
  useEffect(() => {
    if (
      !token ||
      token === null ||
      token === "null" ||
      token === undefined
    ) {
      navigate("/");
    }
  }, [token,navigate]);
  useEffect(() => {
    let robotIdfromURL = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    if(robotIdfromURL){ 
    setRobotId(robotIdfromURL)
    }
  },[]);


  // const DiagnoticsResetfialed = () =>
  //   toast.error("Reset Diagnostics Failed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const ResetDiagnosticsSuccess = () =>
  //   toast.success("Reset Diagnostics Success", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const sendHomeError = () =>
  //   toast.error("Send Home Failed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const sendHomeSuccess = () =>
  //   toast.success("Send Home Success", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const WanderModeError = () =>
  //   toast.error("Wander Mode Failed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const WanderModeSuccess = () =>
  //   toast.success("Wander Mode Successfully Changed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const ringlightError = () =>
  //   toast.error("Ring Light Failed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const ringlightSuccess = () =>
  //   toast.success("Ring Light Successfully Changed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const headlightError = () =>
  //   toast.error("Head Light Failed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const headlightSuccess = () =>
  //   toast.success("Head Light Successfully Changed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  const EmergencyStopError = () =>
    toast.error("Emergency Stop Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const EmergencyStopSuccess = () =>
    toast.success("Emergency Stop Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  // const ReleaseStopError = () =>
  //   toast.error("Release Stop Failed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const ReleaseStopSuccess = () =>
  //   toast.success("Release Stop Successfully", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const OpenDoor1Error = () =>
  //   toast.error("OpenDoor1 Failed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const OpenDoor1Success = () =>
  //   toast.success("OpenDoor1 Successfully", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const OpenDoor2Error = () =>
  //   toast.error("OpenDoor2 Failed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const OpenDoor2Success = () =>
  //   toast.success("OpenDoor2 Successfully", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const CloseDoor1Error = () =>
  //   toast.error("CloseDoor1 Failed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const CloseDoor1Success = () =>
  //   toast.success("CloseDoor1 Successfully", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const CloseDoor2Error = () =>
  //   toast.error("CloseDoor2 Failed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const CloseDoor2Success = () =>
  //   toast.success("CloseDoor2 Successfully", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const playError = () =>
  //   toast.error("Play Failed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const playSuccess = () =>
  //   toast.success("Play Successfully", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const pauseError = () =>
  //   toast.error("Pause Failed", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // const pauseSuccess = () =>
  //   toast.success("Pause Successfully", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });

  const sshEndPoint = useSelector((state) => state.sshendpointReducer);

  const [commandlistdata, setcommandlistdata] = useState();
  // const handlegooglemap = (event) => {
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.checked,
  //   });
  // };


  const updateState = useCallback(async () => {
    let robotIdfromURL = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    if (robotId === null || robotId === "" || robotId === undefined) {
      dispatch(storeRobotDetails(fleetId));

      // console.log("res", res);

      // console.log("result", res);
      let singlerobotdata = Object.keys(robotDetails).filter(
        (item) => item === robotIdfromURL
      );
      // console.log("singlerobotdata", singlerobotdata);
      setRobotId(singlerobotdata.robotId);
    }

    if (isLogged === "LoginFalse") {
      window.location.replace("/");
    } else {
      dispatch(getRobotDetail(robotId));
      // const robotId=localStorage.getItem('robotId')
      // console.log('GetRobotStatus response Dashboard', res)
      // let robotStatus = {};
      // robotStatus.robotId = res.data.data.robotStatus;
      localStorage.setItem("kmlurl", robotDetails[robotId]?.kmlURL);
      // dispatch(getRobotAction(robotId));
      // dispatch(getRobotPath(robotId));
    }
  }, [dispatch, fleetId]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 2000);
  useEffect(() => {
    // socket = io(serverUrl,{transports:["websocket", "polling"]});
    // console.log('Socket===>>', socket)
    //    if(socket)
    //    {   // setTest(true)
    //        console.log("socket start");
    //        socket.on("receiveGreet",data=>{
    //          console.log(data)
    //        })
    //        socket.on('robotDetailStatusChange', (data) => {
    //          //  console.log('data::', data);
    //        //  console.log(data.robotStatus.robotId)
    //         if(data?.robotStatus?.robotId===robotId){

    //          setrobotData(data)
    //          console.log('robotDetailResponse',data)
    //         }
    //      });
    //        socket.on('robotCommandChange', (data) => {
    //          console.log('robotCommandChange',data)
    //          if(data?.robotId===robotId){
    //            setcommandlistdata([data])
    //          }
    //      });

    //  }
    if (isLogged === "LoginFalse") {
      window.location.replace("/");
    } else {
      // const robotId=localStorage.getItem('robotId')

      const robotstatus = setInterval(() => {
        getrobotStatus(robotId, token)
          .then((res) => {
            // console.log('GetRobotStatus response Dashboard', res)
            setrobotData(res.data.data);
            setFleetId(res.data.data.robotStatus.fleetId)
          })
          .catch((err) => {
            console.log("Error in Robot Data", err);
          });
        // setState({
        //   wandermode:Boolean(robotData?.robotStatus?.wanderMode)
        // })
      }, 1500);

      const robotcommand = setInterval(() => {
        robotactionapi(robotId, token)
          .then((res) => {
            setcommandlistdata(res.data.robotCommandList);
            // console.log("res.data.robotCommandList", res.data.robotCommandList);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 2000);

      const robotpath = setInterval(() => {
        getrobotpathMain(robotId, token)
          .then((res) => {
            // console.log('Robotpath responsese',res)
            setrobotpathmain(res.data.path);
          })
          .catch((err) => {
            console.log("Error in robot path", err);
          });
      }, 1500);
      return () => {
        clearInterval(robotstatus);
        clearInterval(robotcommand);
        clearInterval(robotpath);
      };
    }
  }, [dispatch, robotId, token, isLogged, fleetId]);

  useEffect(() => {
    if(fleetId){
      getgeofenceZone(fleetId, token)
        .then((res) => {
          // console.log("Geofence Response", res);
          setgeofencezone(res.data.geofence);
        })
        .catch((err) => {
          console.log("GeoFenceError", err);
        });
      getfleetzones(fleetId, token)
        .then((res) => {
          // console.log("FleetZone Response", res);
          setfleetzones(res.data.teleoperationZones);
        })
        .catch((err) => {
          console.log("FleetZone errr", err);
        });
      getnogozones(fleetId, token)
        .then((res) => {
          // console.log("NogoZone Response", res);
          setnogozones(res.data.nogoZones);
        })
        .catch((err) => {
          console.log("NogoZoneERr", err);
        });
    }
  }, [fleetId,token]);

  const [state, setState] = React.useState({
    testingmode: false,
    wandermode: false,
    ringlight: "",
    headlight: "",
    robotpathcheck: "",
    googlemap: false,
    video: false,
  });

  const handletvideocheck = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 36,
    height: 16,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#000",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#00B7D4" : "#00B7D4",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 12,
      height: 12,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light" ? "#000" : "rgb(189,196,224)",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      color: "rgb(189,196,224)",
      border: "2px solid rgb(189,196,224)",
    },
  }));

  const handlelogout = () => {
    navigate("/");
    localStorage.setItem("useremail", null);
    localStorage.setItem("password", null);
    localStorage.setItem("token", null);
    localStorage.setItem("fleetID", null);
    localStorage.setItem("robotId", null);
    localStorage.setItem("Isloggedin", "LoginFalse");
  };
  // const handlesendHome = (robotId) => {
  //   sendhome(robotId, token)
  //     .then((res) => {
  //       // console.log("SendHomeResponse", res)
  //       sendHomeSuccess();
  //     })
  //     .catch((err) => {
  //       //  console.log('SendHomeError', err)
  //       sendHomeError();
  //     });
  // };
  // const handleStartViewer =(robotId)=>{
  //   startViewer(robotId, token)
  //   .then((res)=>{
  //      console.log('Start Viewer Response', res)
  //      setViewerState(true)
  //   })
  //   .catch((err)=>{
  //      console.log('Error In start Viewer', err)
  //      setViewerState(false)

  //     })

  // }
  // const handleStopViewer=()=>{
  //   stopViewer(robotId, token)
  //   .then((res)=>{
  //     console.log('stop Viewer Response', res)
  //     setViewerState(false)
  //  })
  //  .catch((err)=>{
  //     console.log('Error In Stop Viewer', err)
  //     setViewerState(true)
  //    })
  // }
  // const handleResetDiagnostics = (robotId) => {
  //   resetDiagnostics(robotId, token)
  //     .then((res) => {
  //       //  console.log('Reset Diagnotics Response', res)
  //       ResetDiagnosticsSuccess();
  //     })
  //     .catch((err) => {
  //       //  console.log('Reset Diagnotics Error', err)
  //       DiagnoticsResetfialed();
  //     });
  // };

  // const handleOpendoorone = (robotId , cabin) => {
  //   opendoorone(robotId, token ,cabin)
  //     .then((res) => {
  //       //  console.log("Open Door 1 Response", res)
  //       OpenDoor1Success();
  //     })
  //     .catch((err) => {
  //       //  console.log('Open Door 1 Error',err)
  //       OpenDoor1Error();
  //     });
  // };
  // const handleOpendoortwo = (robotId) => {
  //   opendoortwo(robotId, token)
  //     .then((res) => {
  //       // console.log("Open Door 2 Response", res)
  //       OpenDoor2Success();
  //     })
  //     .catch((err) => {
  //       //  console.log('Open Door 2 Error',err)
  //       OpenDoor2Error();
  //     });
  // };
  // const handleClosedoorone = (robotId) => {
  //   closedoorone(robotId, token)
  //     .then((res) => {
  //       //  console.log("Close Door 1 Response", res)
  //       CloseDoor1Success();
  //     })
  //     .catch((err) => {
  //       //  console.log('Close Door 1 Error',err)
  //       CloseDoor1Error();
  //     });
  // };
  // const handleClosedoortwo = (robotId) => {
  //   closedoortwo(robotId, token)
  //     .then((res) => {
  //       // console.log("Close Door 2 Response", res)
  //       CloseDoor2Success();
  //     })
  //     .catch((err) => {
  //       //  console.log('Close Door 2 Error',err)
  //       CloseDoor2Error();
  //     });
  // };
  const handlebackbutton = () => {
    navigate(-1);
  };

  const handleStop = (robotId) => {
    EmergencyStop(robotId, token)
      .then((res) => {
        // console.log('Emergency Stop Response',res)
        EmergencyStopSuccess();
        setisStop(true);
      })
      .catch((err) => {
        // console.log('Emergency Stop Error',err)
        EmergencyStopError();
        setisStop(false);
      });
  };
  // const handleRelease = (robotId) => {
  //   ReleaseEmergencyStop(robotId, token)
  //     .then((res) => {
  //       // console.log('Release Emergency Stop Response', res)
  //       ReleaseStopSuccess();
  //       setisStop(false);
  //     })
  //     .catch((err) => {
  //       // console.log('Release Emergency Stop Response', err)
  //       ReleaseStopError();
  //       setisStop(true);
  //     });
  // };
  // const handlePauseIcon = (robotId) => {
  //   pauserobot(robotId, token)
  //     .then((res) => {
  //       // console.log('Pause Button Response', res)
  //       pauseSuccess();
  //       setplaybtn(true);
  //     })
  //     .catch((err) => {
  //       // console.log('Pause Button Error' , err)
  //       pauseError();
  //     });
  // };
  // const handlePlayIcon = (robotId) => {
  //   playrobot(robotId, token)
  //     .then((res) => {
  //       //  console.log('Play Button Response', res)
  //       playSuccess();
  //       setplaybtn(false);
  //     })
  //     .catch((err) => {
  //       //  console.log('Play Button Error', err)
  //       playError();
  //     });
  // };

  const getbottombordercolor = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "dashboard-left-side-div-one-wrapper state_Auto_system_fault_error";
      case "ERROR":
        return "dashboard-left-side-div-one-wrapper state_Auto_system_fault_error";
      case "AUTO_OBSTACLE":
        return "dashboard-left-side-div-one-wrapper state_Auto_Obstacle-Teleop_Obstacle";
      case "AUTO_RUN_OBSTACLE":
        return "dashboard-left-side-div-one-wrapper state_Teleop_Autorun";
      case "AUTO_RUN_MOVING":
        return "dashboard-left-side-div-one-wrapper state_Auto_run_moving";
      case "AUTO_RUN":
        return "dashboard-left-side-div-one-wrapper state_Teleop_Autorun";
      case "TELE_OP_OBSTACLE":
        return "dashboard-left-side-div-one-wrapper state_Auto_Obstacle-Teleop_Obstacle";
      case "TELE_OP_MOVING":
        return "dashboard-left-side-div-one-wrapper state_Teleop_Moving";
      case "TELE_OP":
        return "dashboard-left-side-div-one-wrapper state_Teleop_Autorun";
      case "PAYLOAD_STATE":
        return "dashboard-left-side-div-one-wrapper state_payload_state";
      case "MANUAL":
        return "dashboard-left-side-div-one-wrapper state_Teleop_Autorun";
      default:
        return "dashboard-left-side-div-one-wrapper";
    }
  };
  const getbordertop = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoSystem_fault_error_bordertop";
      case "ERROR":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoSystem_fault_error_bordertop";
      case "AUTO_OBSTACLE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoObstacle_teleop_obstacle_bordertop";
      case "AUTO_RUN_OBSTACLE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      case "AUTO_RUN_MOVING":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoRun_moving";
      case "AUTO_RUN":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      case "TELE_OP_OBSTACLE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoObstacle_teleop_obstacle_bordertop";
      case "TELE_OP_MOVING":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center teleopMoving_bordertop";
      case "TELE_OP":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      case "PAYLOAD_STATE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center payloadState_bordertop";
      case "MANUAL":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      default:
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center defaultBorderTop";
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;
  const handleVideorestart = (robotId) => {
    restartvideo(robotId, token)
      .then((res) => {
        console.log("Restart Video Response", res);
        toastmsg(res.data.message);
      })
      .catch((err) => {
        console.log("Restart Video Error", err);
        toastmsg(err.data.message);
      });
  };
  return (
    <>
      <div className="container-fluid DashBoard-page-Main-Whole-wrapper p-2 bg-primaryColor">
        {/* {console.log('Boolean(robotData?.robotStatus?.wandermode)',Boolean(robotData?.robotStatus?.wanderMode))} */}
        {/* {console.log('robotIDrobotID',robotID)} */}
        {/* "!border-[3px] shadow-md !rounded-xl !border-ottonomyBlue "+ */}
        <div
          className={"!rounded-xl "+ getbordertop(robotData?.robotStatus?.robotRunningState)}
        >
          <div className="Dashboard_page_bottom_div_inner_one_div">
            <div className="dashboard_page_Order_Id_Wrapper">
              <div className="dashboard_page_Order_Id_Wrapper_inner_div">
              {!hideLogout&&
                <span style={{ cursor: "pointer" }} onClick={handlebackbutton}>
                  <ArrowBackIosNewIcon
                    style={{ color: "#b1b1b1", fontSize: "37px" }}
                  />
                </span>
              }
                <h3>
                  {robotData?.robotStatus?.robotId}
                  {robotData?.robotStatus?.connectionStatus === "OFFLINE" ? (
                    <p
                      style={{
                        color: "var(--textColor)",
                        marginBottom: "0px",
                        fontWeight: "",
                        padding: "",
                        fontWeight: "200",
                        textAlign: "center",
                        fontSize: "12px",
                        paddingTop: "0px",
                        paddingLeft: "0px",
                      }}
                    >
                      {" "}
                      {robotData?.robotStatus?.lastUpdatedTime}
                    </p>
                  ) : null}
                </h3>
              </div>
              {/* {console.log("robotDatarobotDatarobotData", robotData)} */}
              <div style={{ alignSelf: "center" }}>
                <span
                  className={
                    robotData?.robotStatus?.connectionStatus === "OFFLINE"
                      ? "p-color-red"
                      : "p-color-green"
                  }
                >
                  {robotData?.robotStatus?.connectionStatus}
                </span>
              </div>
              {/* <div> 
                       {playbtn ?<><PlayCircleIcon style={{marginBottom:'', fontSize:'30px'}} onClick={()=>handlePlayIcon(robotData?.robotStatus?.robotId)} className="play-icon p-color-red"/></>:<><PauseCircleIcon style={{marginBottom:'',fontSize:'30px'}} className="play-icon p-color-green" onClick={()=>handlePauseIcon(robotData?.robotStatus?.robotId)}/></>}
                   </div> */}
            </div>
          </div>
          <div className="Dashboard_page_bottom_div_inner_two_div">
            <div className="Dashboard_page_bottom_div_inner_two_div_inner_one">
              {/* <div className="Dashboard_page_top_div_inner_div"> 
        <p className='Dashboard-Page-bottom-div-Main-wrapper-content-p'> Wander Mode</p>
        <FormControlLabel
        control={<IOSSwitch  checked={state.wandermode} onChange={handlewanderChange} name="wandermode"/>}
        label=""
         />
      </div> */}
              {/* <div className="Dashboard_page_top_div_inner_div">
                <p className="Dashboard-Page-bottom-div-Main-wrapper-content-p">
                  {" "}
                  Ring Light
                </p>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={state.ringlight}
                      onChange={handleringlightChange}
                      name="ringlight"
                    />
                  }
                  label=""
                />
              </div> */}
              {/* <div className="Dashboard_page_top_div_inner_div">
                <p className="Dashboard-Page-bottom-div-Main-wrapper-content-p">
                  {" "}
                  Head Light
                </p>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={state.headlight}
                      onChange={handleheadlightchange}
                      name="headlight"
                    />
                  }
                  label=""
                />
              </div> */}

              <div className="Dashboard_page_top_div_inner_div">
                <p className="Dashboard-Page-bottom-div-Main-wrapper-content-p">
                  {" "}
                  Total Distance Covered{" "}
                </p>{" "}
                <span className="Dashboard-Page-bottom-div-Main-wrapper-content-span">
                  {" "}
                  {(
                    parseFloat(
                      robotData?.robotStatus?.totalDistanceTravelled * 1.60934
                    ) / 20000
                  ).toFixed(2)}{" "}
                  Km
                </span>
              </div>
              {/* <div className="Dashboard_page_top_div_inner_div"> 
        <p className='Dashboard-Page-bottom-div-Main-wrapper-content-p'> Google Map</p>
        <FormControlLabel
        control={<IOSSwitch checked={state.googlemap} onChange={handlegooglemap} name="googlemap"/>}
        label=""
        />
      </div>  */}
            </div>
            <div className="Dashboard_page_bottom_div_inner_two_div_inner_two">
              {hasPermissionForRestartStream ? (
                <button
                  className="restart_video_button button_two"
                  onClick={() =>
                    handleVideorestart(robotData?.robotStatus?.robotId)
                  }
                >
                  {" "}
                  Restart Video{" "}
                </button>
              ) : null}
              {hasPermissionForRobotStream && (
                <div className=" direction-toggle">
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.video}
                        onChange={handletvideocheck}
                        name="video"
                      />
                    }
                    label=""
                  />
                  <p className="Dashboard-Page-bottom-div-Main-wrapper-content-p direction_toggle_p">
                    Video Stream
                  </p>
                </div>
              )}
              {/* <LogOutModal dashboard={true} /> */}
              {/* {viewerState ? <button onClick={()=>handleStopViewer(robotData?.robotStatus?.robotId)}>Stop Viewer </button> :<button onClick={()=>handleStartViewer(robotData?.robotStatus?.robotId)}>Start Viewer </button> } */}
              {/* <button
                onClick={() =>
                  handleResetDiagnostics(robotData?.robotStatus?.robotId)
                }
              >
                Reset Diagnostics{" "}
              </button>
              {isstop ? (
                <button
                  className="Stop-button-dashhboard-page"
                  onClick={() => handleRelease(robotData?.robotStatus?.robotId)}
                >
                  {" "}
                  Release{" "}
                </button>
              ) : (
                <button
                  className="Stop-button-dashhboard-page"
                  onClick={() => handleStop(robotData?.robotStatus?.robotId)}
                >
                  {" "}
                  E-STOP{" "}
                </button>
              )} */}
              <div>
                {/* <p className='Dashboard-Page-bottom-div-Main-wrapper-content-p'> Testing Mode</p>
        <FormControlLabel
        control={ <IOSSwitch checked={state.testingmode} onChange={handletestingChange} name="testingmode"/>}
        label=""
        /> */}
              </div>
              {!hideLogout &&
              <LogOutModal />
              }
              {/* <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 5.5,
                    width: "20ch",
                    background: "black",
                  },
                }}
              > */}{" "}
              {/* <MenuItem className="Dashboard_page_sidebar_menuItem">
                  <DisengageMotorsModal
                    robotId={robotData?.robotStatus?.robotId}
                    robotData={robotData}
                  />
                </MenuItem> */}
              {/* <MenuItem className="Dashboard_page_sidebar_menuItem">
                  <button
                    onClick={() =>
                      handlesendHome(robotData?.robotStatus?.robotId)
                    }
                    className="Dashboard_page_sidebar_button"
                  >
                    Send Home{" "}
                  </button>
                </MenuItem>
                <MenuItem className="Dashboard_page_sidebar_menuItem">
                  <OpenSSHModal
                    robotId={robotId}
                    fleetId={fleetId}
                    robotData={robotData}
                  />
                </MenuItem> */}
              {/* <MenuItem className="Dashboard_page_sidebar_menuItem">
                  <div className="Dashboard_page_top_div_inner_div direction-toggle">
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={state.robotpathcheck}
                          onChange={handletrobotpath}
                          name="robotpathcheck"
                        />
                      }
                      label=""
                    />
                    <p className="Dashboard-Page-bottom-div-Main-wrapper-content-p direction_toggle_p">
                      Directions
                    </p>
                  </div>
                </MenuItem> */}
              {/* <MenuItem className="Dashboard_page_sidebar_menuItem_last">
                     <div className="Dashboard_page_top_div_inner_div direction-toggle">
                              <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={state.video}
                                onChange={handletvideocheck}
                                name="video"
                              />
                            }
                            label=""
                          />
                                  <p className="Dashboard-Page-bottom-div-Main-wrapper-content-p direction_toggle_p">
                                     Video 
                                  </p>
                        </div>
                   </MenuItem> */}
              {/* <MenuItem className="Dashboard_page_sidebar_menuItem_last">
                      <div>
                      <button className="restart_video_button" onClick={()=>handleVideorestart(robotData?.robotStatus?.robotId)}> Restart Video </button>
                      </div>
                    </MenuItem> */}
              {/* <MenuItem className="Dashboard_page_sidebar_menuItem_last">
                      <div>
                        <LogOutModal dashboard={true} />
                        <PowerSettingsNewIcon style={{color:'#b1b1b1', cursor:'pointer'}} onClick={handlelogout} className="Dashboard-Page-Power-button"/>
                      </div>
                    </MenuItem> */}
              {/* </Menu> */}
            </div>
          </div>
        </div>
        <div
          className="row mt-4 w-full"
          style={{ paddingLeft: "0px", marginBottom: "0rem" }}
        >
          <div className="col-md-3 dashboard-left-side-div-wrapper-Main">
            <div
              className={ "p-2 "+getbottombordercolor(
                robotData?.robotStatus?.robotRunningState
              )}
            >
              {parseFloat(robotData?.robotStatus?.robotBattery).toFixed(1) <=
                20 && (
                <span
                  style={{
                    position: "relative",
                    padding: "6px",
                    backgroundColor: "#fb6262",
                    borderRadius: "25px",
                    position: "absolute",
                    top: "-24px",
                    right: "-24px",
                  }}
                >
                  <img src="/assets/images/batteryswa.svg" alt="" />
                  <span
                    style={{
                      zIndex: "1",
                      position: "absolute",
                      top: "-4px",
                      right: "32px",
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "2px",
                      paddingLeft: "2px",
                      paddingRight: "4px",
                      width: "max-content",
                      gap: "2px",
                      alignItems: "center",
                      borderRadius: "20px",
                      backgroundColor: "#fb6262",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        fontStyle: "italic",
                        color: "white",
                        fontWeight: "300px",
                        fontFamily: "sans-serif",
                      }}
                      className="text-[10px] text-textColor font-lilita italic font-light"
                    >
                      Battery Swap Required
                    </p>
                    <AiFillInfoCircle
                      style={{ color: "white", width: "13px", height: "13px" }}
                    />
                  </span>
                </span>
              )}
              <div className="dashboard-left-side-div-one-inner-div dashboard_page_battery_icon_wrapper">
                <div className="dashboard_left_side_available_div">
                  {robotData?.robotStatus?.robotStatus === "AVAILABLE" ? (
                    <span className="m-0 text-left p-color-green">
                      AVAILABLE
                    </span>
                  ) : (
                    <span className="m-0 text-left p-color-red">
                      {" "}
                      UNAVAILABLE
                    </span>
                  )}
                  <p
                    style={{
                      color: "var(--textColor)",
                      marginBottom: "0px",
                      fontWeight: "200",
                      padding: "0px",
                      textAlign: "center",
                      fontSize: "13px",
                    }}
                  >
                    {" "}
                    {robotData &&
                      parseFloat(robotData?.robotStatus?.currentSpeed).toFixed(
                        2
                      )}{" "}
                    m/sec
                  </p>
                </div>
                <div>
                  <div className="dashboard-left-side-div-one-wrapper-battery-div">
                    <div className="battery-icon-css_two">
                      <span
                        style={{
                          width: "30px",
                          border: "1px solid var(--textColor)",
                          borderRadius: "4px",
                          padding: "2px",
                          height: "14px",
                          display: "inline-block",
                        }}
                      >
                        <strong
                          style={{
                            background:
                              parseInt(
                                robotData?.robotStatus?.robotBattery
                              ).toFixed(0) <= 20
                                ? "#E4261C"
                                : "rgb(113, 207, 98)",
                            width: `${parseInt(
                              robotData?.robotStatus?.robotBattery
                            ).toFixed(0)}%`,
                            display: "block",
                            height: "8px",
                          }}
                        ></strong>
                      </span>
                      {/* {console.log('robotData?.robotStatus?.robotBattery',robotData?.robotStatus?.robotBattery)} */}
                    </div>
                    <p>
                      {parseInt(robotData?.robotStatus?.robotBattery).toFixed(
                        0
                      )}
                      %
                    </p>
                  </div>
                  <span
                    style={{
                      background: "var(--textColor)",
                      width: "3px",
                      height: "5px",
                      display: "inline-block",
                      borderRadius: "3px",
                      marginRight: "3px",
                    }}
                  ></span>
                  <span
                    style={{
                      background: "var(--textColor)",
                      width: "3px",
                      height: "10px",
                      display: "inline-block",
                      borderRadius: "3px",
                      marginRight: "3px",
                    }}
                  ></span>
                  <span
                    style={{
                      background: "var(--textColor)",
                      width: "3px",
                      height: "15px",
                      display: "inline-block",
                      borderRadius: "3px",
                      marginRight: "3px",
                    }}
                  ></span>
                  <span
                    style={{
                      background: "var(--textColor)",
                      width: "3px",
                      height: "20px",
                      display: "inline-block",
                      borderRadius: "3px",
                      marginRight: "3px",
                      opacity: "0.5",
                    }}
                  ></span>
                  <span
                    style={{
                      background: "var(--textColor)",
                      width: "3px",
                      height: "25px",
                      display: "inline-block",
                      borderRadius: "3px",
                      marginRight: "3px",
                      opacity: "0.5",
                    }}
                  ></span>
                </div>
              </div>
              <AutorunMoving robotId={robotId} robotData={robotData} />
              <TeleopCompleted robotId={robotId} robotData={robotData} />
            </div>

            <div className="dashboard-left-side-div-two-wrapper"></div>

            <div className="dashboard-left-side-div-three-wrapper rounded-xl shadow-md">
              <h5 className="dashboard-left-side-div-two-wrapper-h5 !bg-bodycolor px-2 py-2">
                {" "}
                <span>
                  <MeetingRoomOutlinedIcon style={{ color: "#b1b1b1" }} />{" "}
                </span>
                Ottobot Controls
              </h5>
              <div className="dashboard-left-side-div-two-robot-button-Container d-flex-center px-2">
                {robotData?.robotStatus?.cabinStatus?.length > 0 ? (
                  <>
                    {robotData?.robotStatus?.robotRunningState ===
                    "PAYLOAD_STATE" ? (
                      <>
                        {" "}
                        {robotData?.robotStatus?.cabinStatus.map(
                          (item, key) => {
                            if (
                              robotData?.robotStatus?.doorStatus[key] === "OPEN"
                            ) {
                              return (
                                <button
                                  key ={key}
                                  className={
                                    item === "0"
                                      ? "Dashboardpage_CloseDoor_button opendoor_btn_green"
                                      : "Dashboardpage_CloseDoor_button"
                                  }
                                  // onClick={() =>
                                  //   handleClosedoorone(robotData?.robotStatus?.robotId)
                                  // }
                                >
                                  Cabin {key + 1}
                                </button>
                              );
                            } else {
                              return (
                                <button
                                key ={key}
                                  className={
                                    item === "0" ? "opendoor_btn_green" : ""
                                  }
                                  //  onClick={() =>
                                  //    handleOpendoorone(robotData?.robotStatus?.robotId , `CABIN${key+1}`)
                                  //  }
                                >
                                  Cabin {key + 1}
                                </button>
                              );
                            }
                          }
                        )}
                      </>
                    ) : (
                      <>
                        {robotData?.robotStatus?.cabinStatus.map(
                          (item, key) => {
                            return (
                              <button
                              key ={key}
                                className={
                                  item === "0"
                                    ? "opendoor_btn_green Dashboard_page_OpenDoor_deactivate"
                                    : "Dashboard_page_OpenDoor_deactivate"
                                }
                                type="button"
                                disabled
                              >
                                {" "}
                                Cabin{key + 1}{" "}
                              </button>
                            );
                          }
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    {robotData?.robotStatus?.robotRunningState ===
                    "PAYLOAD_STATE" ? (
                      <>
                        {" "}
                        {robotData?.robotStatus?.door1Status === "CLOSE" ? (
                          <button
                          // onClick={() =>
                          //   handleOpendoorone(robotData?.robotStatus?.robotId)
                          // }
                          >
                            {" "}
                            Cabin 1{" "}
                          </button>
                        ) : (
                          <button
                            className="Dashboardpage_CloseDoor_button"
                            // onClick={() =>
                            //   handleClosedoorone(robotData?.robotStatus?.robotId)
                            // }
                          >
                            {" "}
                            Close Cabin 1{" "}
                          </button>
                        )}
                        {robotData?.robotStatus?.door2Status === "CLOSE" ? (
                          <button
                          // onClick={() =>
                          //   handleOpendoortwo(robotData?.robotStatus?.robotId)
                          // }
                          >
                            {" "}
                            Cabin 2{" "}
                          </button>
                        ) : (
                          <button
                            className="Dashboardpage_CloseDoor_button"
                            // onClick={() =>
                            //   handleClosedoortwo(robotData?.robotStatus?.robotId)
                            // }
                          >
                            {" "}
                            Cabin 2{" "}
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {" "}
                        <button
                          className="Dashboard_page_OpenDoor_deactivate"
                          type="button"
                          disabled
                        >
                          {" "}
                          Open Cabin 1{" "}
                        </button>
                        <button
                          className="Dashboard_page_OpenDoor_deactivate"
                          type="button"
                          disabled
                        >
                          {" "}
                          Open Cabin 2{" "}
                        </button>{" "}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="dashboard-left-side-div-four-wrapper rounded-xl shadow-md">
              <h5 className="dashboard-left-side-div-two-wrapper-h5 !bg-bodycolor px-2 py-2">
                {" "}
                <span>
                  <SettingsOutlinedIcon style={{ color: "#b1b1b1" }} />{" "}
                </span>
                Ottobot Actions
              </h5>
              <div className="dashboard-left-side-div-two-Map-Container-two">
                <RobotActions
                  robotId={robotData?.robotStatus?.robotId}
                  robotData={robotData}
                />
              </div>
            </div>
          </div>
          <div
            className="col-md-9 dashboard-right-side-div-wrapper-Main"
            style={{ padding: "0px", position: "relative" }}
          >
            {(!isNaN(robotData?.robotStatus?.latitude) ||
              !isNaN(robotData?.robotStatus?.longitude) ||
              robotData?.robotStatus?.latitude !== undefined ||
              robotData?.robotStatus?.longitude !== undefined) && (
              <>
                {/* {state.googlemap ? */}
                <>
                  {/* <GoogleMapMain
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBRQzEYlLWkb-swa-hIj5YW9F1Kz-ixg0M&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: mapheight }} />}
                    mapElement={<div style={{ height: mapheight }} />}
                    mapchecktwo={true}
                    robotId={robotId}
                    robotlatitude={parseFloat(robotData?.robotStatus?.latitude)}
                    robotlongitude={parseFloat(
                      robotData?.robotStatus?.longitude
                    )}
                    commandlistdata={commandlistdata}
                    robotpathmain={robotpathmain}
                    robotpathcheck={state.robotpathcheck}
                    geofencezone={geofencezone}
                    fleetzones={fleetzones}
                    nogozones={nogozones}
                  /> */}
                  {nogozones &&
                    geofencezone &&
                    fleetzones &&
                    robotId &&
                    robotData?.robotStatus?.latitude &&
                    robotData?.robotStatus?.longitude && (
                      <MapboxMain
                        robotpathcheck={state.robotpathcheck}
                        video={state.video}
                        robotId={robotId}
                        geofencezone={geofencezone}
                        nogozones={nogozones}
                        fleetzones={fleetzones}
                        robotlatitude={
                          robotData?.robotStatus?.latitude &&
                          parseFloat(robotData?.robotStatus?.latitude)
                        }
                        robotlongitude={
                          robotData?.robotStatus?.longitude &&
                          parseFloat(robotData?.robotStatus?.longitude)
                        }
                        // state2={state2}
                      />
                    )}
                </>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(RobotIndividual);
